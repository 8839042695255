import Modal from "components/modal";
import { useFormik } from "formik";
import { X } from "lucide-react";
import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { sendSmsViaBusiness } from "store/business/businessAction";
import { useAppDispatch, useAppSelector } from "store/index";

interface smsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  noteID?: string;
  contact?: any;
  contactID?: string;
  businessName: string;
  businessID?: string;
}

interface FileData {
  name: string;
  file: File;
}

const SMSModal = ({
  isOpen,
  onClose,
  title,
  noteID,
  contact,
  contactID,
  businessName,
  businessID,

  ...rest
}: smsModalProps) => {
  const { currentUser, isSalesUser } = useAppSelector(
    (state) => state.authUserReducer
  );

  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);

  const formik = useFormik({
    initialValues: {
      message: "",
      businessUUID: businessID,
      attachment: [] as FileData[],
    },
    onSubmit: async (values, { resetForm }) => {
      const smsFrom = isSalesUser ? currentUser?.phone : "";

      if (selectedFiles) {
        values.attachment = selectedFiles;
      }

      toast.promise(
        dispatch(
          sendSmsViaBusiness(
            { ...values, smsFrom },
            { id: contactID, phone: contact }
          )
        ),
        {
          loading: "Sending SMS",
          success: () => {
            resetForm();
            setSelectedFiles([]);
            onClose();
            return "SMS sent successfully";
          },
          error: () => {
            return "Something went wrong";
          },
        }
      );
    },
  });

  const dispatch = useAppDispatch();

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;

    if (files) {
      const filesArray: FileData[] = Array.from(files).map((file) => ({
        name: file.name,
        file: file,
      }));

      setSelectedFiles([...selectedFiles, ...filesArray]);
      console.log(files);

      formik.setFieldValue("attachments", files);
    }
  };

  const handleFileRemove = (fileName: string) => {
    const newFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(newFiles);
    formik.setFieldValue("attachments", newFiles);
  };

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} {...rest}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 px-6">
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="key">
              SMS Content
            </label>
            <textarea
              className="py-1 px-2 border rounded-sm focus:outline-blue-500/50"
              id="key"
              onChange={(e) => formik.setFieldValue("message", e.target.value)}
              rows={3}
            />
          </div>
          <div className="flex gap-1 flex-col">
            <label className="text-[14px] font-medium" htmlFor="value">
              Attachments
            </label>
            <input
              className="border rounded-sm focus:outline-blue-500/50 file:border-none file:py-2"
              onChange={handleFileSelect}
              id="value"
              type="file"
            />
            {!!selectedFiles?.length && (
              <div className="flex gap-2 py-2 border-t flex-wrap">
                {selectedFiles?.map((file: any) => (
                  <div
                    className=" w-fit items-center justify-between px-2 flex gap-2 border rounded-full"
                    key={file?.name}
                  >
                    <p className=" text-[#9f9f9f] text-[12px] italic max-w-[64px] line-clamp-1 text-ellipsis">
                      {file?.name}
                    </p>
                    <button
                      className="p-[1px] bg-slate-200 rounded-full text-[#9f9f9f]"
                      onClick={() => handleFileRemove(file.name)}
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex gap-6 mt-4 w-full justify-center">
            <button
              onClick={onClose}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 "
            >
              Cancel
            </button>
            <button
              onClick={() => formik.handleSubmit()}
              className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
            >
              Send SMS
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SMSModal;
