import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { useQueryParams } from "hooks/useQueryParams";

interface ItabList {
  value: string;
  name: string;
  count?: number;
}
interface SwitchTabContentProps {
  tablist: ItabList[];
  value: string;
  onChange: (e: string) => void;
}

export const SwitchTabs = ({
  value,
  tablist,
  onChange,
}: SwitchTabContentProps) => {
  return (
    <Tabs.Root className="w-fit  flex flex-col" value={value}>
      <Tabs.List className="flex ">
        {tablist?.map((tab, index) => {
          return (
            <Tabs.Trigger
              key={index}
              onClick={() => onChange && onChange(tab.value)}
              value={tab.value}
              className={`data-[state=active]:font-medium text-[14px] w-fit font-medium p-2 line-clamp-1  lg:p-4 border-b-2 data-[state=active]:border-b-2  data-[state=active]:text-purple-950 text-black/40 hover:text-black/70  data-[state=active]:border-purple-950  border-black/20`}
            >
              {`${tab.name} ${tab.count ? `(${tab.count})` : ""}`}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
      {/* 
      <div>
        {tablist.map((tab, index) => {
          return (
            <Tabs.Content key={index} className="p-4" value={tab.name}>
              {tab.content}
            </Tabs.Content>
          );
        })}
      </div> */}
    </Tabs.Root>
  );
};

export default SwitchTabs;
