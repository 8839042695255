import { Dispatch } from "redux";
import API from "../../api";
// import { fetchNoteList } from "../../../views/home/services/homeAction";
import store from "../";
import { slack_data_interface } from "../../utils/slack_integration";
import { extractUsername } from "../../utils/extractUsername";
import { PUBLIC_URL } from "../../constants/constant";
import toast from "react-hot-toast";
import { createCommentFunc } from "./comment_action";
import { NoteStatus } from "constants/noteStatus";

const errorResponse = () => {
  toast.error("Error: Failed To Update Note");
};
export const changeNoteAssignedTo = async (value: any) => {
  try {
    const { currentUser } = store.getState().authUserReducer;
    const from_user = `${currentUser.first_name} ${currentUser.last_name}`;

    const url = window.location.hostname;
    const protocol = window.location.protocol;
    const currentUrl = `${protocol}//${url}/app/note/${value.noteUUID}`;

    if (value.userUUID) {
      const createCommentdata = {
        content: `Ticket moved  ${
          value.from_user ? "from " + value.from_user : ""
        } to ${value.to_user}`,
        noteID: value.noteUUID,
        userID: currentUser.id,
        type: "INFO",
      };

      console.log(createCommentdata);

      const comment: any = await API.post(
        "/notes/createcomment",
        createCommentdata
      );
      if (!comment.data.id) {
        errorResponse();
      }
      const updateNoteAssignedData = {
        ROWID: value.noteUUID,
        userID: value.userUUID,
        lastActionID: currentUser.id,
        status: "OPEN",
      };

      const note: any = await API.post("/notes/update", updateNoteAssignedData);
      if (!note.data.id) {
        errorResponse();
      }

      if (process.env.NODE_ENV === "production") {
        try {
          // If ticket is assigned to Ashim Shrestha, send notification to campaign request channel
          if (value.to_user === "Ashim Shrestha") {
            const slack_notification_body: slack_data_interface = {
              text: `Hey <@U07KTKSL42F>! A new ticket has been assigned to you by ${from_user}. Here is a direct link to the ticket: ${currentUrl}`,
            };

            const response = await API.post(
              "/notes/send-campaign-request",
              slack_notification_body
            );
            console.log("response for send-campaign-request", response);
          }
        } catch {}
      }
    } else if (value.from_user) {
      console.log(value.from_user);
      const createCommentData = {
        content: "Assignment removed from ticket",
        noteID: value.noteUUID,
        userID: currentUser.id,
        type: "INFO",
      };

      const comment: any = await API.post(
        "/notes/createcomment",
        createCommentData
      );
      if (!comment.data.id) {
        errorResponse();
      }
      const updateNoteAssignedData = {
        userID: null,
        ROWID: value.noteUUID,
        lastActionID: currentUser.id,
        status: "",
      };

      const note: any = await API.post("/notes/update", updateNoteAssignedData);
      if (!note.data.id) {
        errorResponse();
      }
      // dispatch({ type: CLEAR_NOTE, noteID: value.noteUUID });
      // dispatch(fetchNoteList(store.getState().homeReducer.searchFilter));
      toast.success("Success: Note assignee updated");
    }
  } catch (e) {
    errorResponse();
  }
};

export const deleteNoteByID = async (note: any) => {
  try {
    await API.post("/notes/delete", { id: note.id });
  } catch (e: any) {
    console.log(e);
  }
};

export const updateNoteReaction =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      toast.promise(
        API.post("/notes/reaction/update", {
          ...payload,
          url: `${PUBLIC_URL}/app/note/${payload.ROWID}`,
        }),
        {
          loading: `Updating Reaction`,
          success: () => `Reaction Updated`,
          error: (error) => `Error: ${error}`,
        }
      );
    } catch (e) {
      toast.error(`Error: ${e}`);
      // errorResponse();
    }
  };

export const updateContactByNotes =
  (values: any) => async (dispatch: Dispatch<any>) => {
    try {
      console.log("business_id", values.contactUUID);
      const updateContact: any = await API.post("/contact/update", {
        ROWID: values.contactUUID,
        name: values.contactName,
        businessID: values.businessUUID,
      });
      if (!updateContact.data.id) {
        console.log("Error occured");
      }
    } catch (e) {
      console.log("Error occured");
    }
  };

export const updateNoteStatus = async ({
  updatedNoteStatus,
  noteStatusUpdateAt,
  currentNoteStatus,
  noteId,
  noteType,
}: any) => {
  try {
    const currentUser = store.getState().authUserReducer.currentUser;
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    const currentUserId = currentUser ? currentUser.id : "";
    let noteStatusChangeComment;
    try {
      if (updatedNoteStatus !== currentNoteStatus) {
        // console.log('only note status is changed.');
        noteStatusChangeComment = `Note Status changed to ${updatedNoteStatus} ${
          updatedNoteStatus === "CLOSED" && noteType !== "TICKET"
            ? ` and ticket close ${
                noteType === "EMAIL"
                  ? "EMAIL"
                  : ["CALL", "SMS", "CALL_OUT"].includes(noteType)
                  ? "SMS"
                  : "notification"
              } sent`
            : ""
        }`;
        await API.post("/notes/update", {
          ROWID: noteId,
          lastActionID: currentUserId,
          status: updatedNoteStatus,
          noteStatusUpdateAt,
        });
      } else {
        toast.error("Error: Cannot set the same note status");
      }
    } catch (e: any) {
      toast.error(e);
    }

    if (noteStatusChangeComment)
      await createCommentFunc({
        noteId,
        userId: currentUserId,
        commentContent: noteStatusChangeComment,
      });

    // dispatch({ type: CLEAR_NOTE, noteID: values.note_id });
    //   dispatch(fetchNoteList(store.getState().homeReducer.searchFilter));
  } catch (e) {
    toast.error("Error: Failed to Update note status.");
  }
};

const storeAttachments = async (objs: any, note_id: string) => {
  let success = true;
  if (!objs.length) return;
  const res = await storeNoteFile(objs, note_id);
  if (!res) success = false;
  console.log(success);
  return success;
};

export const storeNoteFile = async (attachment: any, note_id: string) => {
  try {
    const data = new FormData();
    for (let i = 0; i < attachment.length; i++) {
      data.append("files", attachment.item(i));
    }
    data.append("noteID", note_id);

    const res: any = await API.post(
      `${PUBLIC_URL}/server/growthzilla_notes_function/uploadfile`,
      data,
      {}
    );
    console.log("attachments uploaded", res.data);
    return res.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const addNote = async (values: any) => {
  console.log(values);
  const currentuser = store.getState().authUserReducer.currentUser;
  const isSalesUser = store.getState().authUserReducer.isSalesUser;
  const newNote: any = await API.post("/notes/create", {
    userID: values.userUUID,
    businessID: values.businessUUID,
    content: values.content,
    status: NoteStatus.UNMANAGED,
    ticketBy: extractUsername(currentuser.email || ""),
    type: "TICKET",
    channel: isSalesUser ? "SALES" : undefined,
  });
  const noteID = newNote?.data && newNote?.data?.id;
  if (noteID) {
    if (values.attachments) {
      const attachmentRes: any = await storeAttachments(
        values.attachments,
        noteID
      );
      console.log(attachmentRes);
      // if (attachmentRes) {
      //   // const res = await storeNoteAttachments(attachmentRes, noteID);
      //   console.log(attachmentRes);
      // }
      return attachmentRes;
    }
  } else toast.error("Error: New note creation failed ");
};
