import {
  Video,
  useMembers,
  useScreenShare,
  useStatus,
} from "@signalwire-community/react";
import API from "api/index";
import Modal from "components/modal";
import NoteAddComment from "features/noteContainer/NoteCard/NoteAddComment";
import NoteCardHeader from "features/noteContainer/NoteCard/NoteCardHeader";
import NoteCommentField from "features/noteContainer/NoteCard/NoteCommentField";
import {
  Eraser,
  Mic,
  MicOff,
  Monitor,
  MonitorOff,
  PenTool,
  PhoneOff,
  Share2,
  User,
  VideoIcon,
  VideoOff,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import store from "store/index";
import { createCommentFunc } from "store/notes/comment_action";

interface Circle {
  x: number;
  y: number;
  height: number;
  width: number;
  radius: number;
  number: number;
  color: string;
}

interface database {
  timing: number;
  height: number;
  width: number;
  radius: number;
  x: number;
  y: number;
  number: number;
  type: string;
  recording_id: string;
  color: string;
}

interface Metadata {
  height: number;
  width: number;
  circleData: Circle[];
  leave: boolean;
  aspectRatio?: number;
}

interface CanvasProps {
  token: string;
  noteId: string;
  closeModal: () => void;
  invite?: string;
  contact_phone?: string;
  createComment?: (comment: any, type: string) => void;
  modalOpen?: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoCallModal = ({
  isOpen,
  onClose,
  token,
  inviteLink,
  notes,
  title,
  businessName,
  ...rest
}: any) => {
  //video conference setup
  const [roomSession, setRoomSession] = useState<any>(null);
  const [roomJoined, setRoomJoined] = useState(false);
  const [open, setOpen] = useState(false);
  const { self, members } = useMembers(roomSession);
  const { active } = useStatus(roomSession);

  const { toggle, active: screenShareActive } = useScreenShare(roomSession);
  const [selectedOption, setSelectedOption] = React.useState("");
  const onRoomReady = useCallback((rs: any) => setRoomSession(rs), []);
  const [metadata, setMetadata] = useState<any>({});
  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, muteVideo] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [isAnotherNumber, setAnotherNumber] = useState<boolean>(false);
  const [anotherPhoneNumber, setAnotherPhoneNumber] = React.useState("");
  //canvas settings
  const [windowsize, setWindowSize] = useState({ width: 0, height: 0 });
  const [canvadata, setCanvaData] = useState<database[]>([]);
  const drawCanvasRef = useRef<HTMLCanvasElement>(null);
  const displayCanvasRef = useRef<HTMLCanvasElement>(null);
  const [canvaSize, setCanvaSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });
  const isDrawing = useRef(false);
  const circles = useRef<Circle[]>([]);
  const [showCanva, setShowCanva] = useState<boolean>(false);
  const counter = useRef(1);
  const startCoords = useRef({ x: 0, y: 0 });

  // const {,clearCanvas} = useCircleDrawer({canvasRef, circleSize:32});
  const [startTime, setStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  // console.log(circles);

  // const {currentUser} = useAppSelector(state=> state.authUserReducer)
  // const [startTime, setStartTime] = useState<number | null>(null);
  // const [elapsedTime, setElapsedTime] = useState<number>(0);
  //   const createComment = async (noteComment: string,type:string) => {
  //     try {
  //         await createCommentFunc({
  //           noteId:notes?.id,
  //           userId: currentUser?.id,
  //           commentContent: noteComment,},type);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  useEffect(() => {
    const handleResize = () => {
      const rect = document.getElementById("video")?.getBoundingClientRect();
      // const rect = document.getElementById("video")!.getElementsByTagName("video")[0].getBoundingClientRect();

      setCanvaSize({
        width: metadata.aspectRatio ? metadata.aspectRatio : rect?.width,
        height: rect?.height || 0,
        top: rect?.top || 0,
        left: rect?.left || 0,
      });

      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let drawCanvas: HTMLCanvasElement | null = drawCanvasRef?.current;
    let drawContext: CanvasRenderingContext2D | null | undefined =
      drawCanvasRef?.current?.getContext("2d");

    const handleMouseDown = (event: MouseEvent) => {
      isDrawing.current = true;
      startCoords.current = getCanvasCoordinates(event);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDrawing.current) return;

      const currentCoords = getCanvasCoordinates(event);
      const radius = Math.sqrt(
        Math.pow(currentCoords.x - startCoords.current.x, 2) +
          Math.pow(currentCoords.y - startCoords.current.y, 2)
      );

      redrawCanvas();

      drawContext!.beginPath();
      drawContext!.arc(
        startCoords.current.x,
        startCoords.current.y,
        radius,
        0,
        2 * Math.PI
      );
      drawContext!.strokeStyle = "red";
      drawContext!.lineWidth = 5;
      drawContext!.stroke();
      drawContext!.closePath();

      drawContext!.fillStyle = "red";
      drawContext!.font = "25px Arial";
      drawContext!.textAlign = "center";
      drawContext!.textBaseline = "middle";
      drawContext!.fillText(
        counter.current.toString(),
        startCoords.current.x,
        startCoords.current.y
      );
    };

    function handleMouseUp(event: MouseEvent) {
      if (isDrawing.current) {
        const currentCoords = getCanvasCoordinates(event);
        const radius = Math.sqrt(
          Math.pow(currentCoords.x - startCoords.current.x, 2) +
            Math.pow(currentCoords.y - startCoords.current.y, 2)
        );

        const color = getRandomColor();
        const circle = {
          x: startCoords.current.x,
          y: startCoords.current.y,
          height: canvaSize.height,
          width: canvaSize.width,
          radius,
          number: counter.current,
          color,
        };

        setCanvaData([
          ...canvadata,
          {
            x: Math.floor(startCoords.current.x * 100000),
            y: Math.floor(startCoords.current.y * 1000),
            height: Math.floor(canvaSize.height * 1000),
            width: Math.floor(canvaSize.width * 1000),
            radius: Math.floor(radius * 1000),
            number: counter.current,
            color,
            type: "draw",
            timing: elapsedTime,
            recording_id: "",
          },
        ]);
        circles.current.push(circle);
        counter.current++;
      }

      isDrawing.current = false;
      redrawCanvas();

      console.log("roomSession", roomSession);
      roomSession
        .updateMeta({
          circleData: circles.current,
          width: drawCanvas!.width,
          height: drawCanvas!.height,
        })
        .then(() => {
          console.log("Meta updated successfully.");
        })
        .catch((error: any) => {
          console.log("Failed to update meta:", error);
        });
    }

    function getCanvasCoordinates(event: MouseEvent) {
      const rect = drawCanvas!.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      return { x, y };
    }

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    function redrawCanvas() {
      drawContext!.clearRect(0, 0, drawCanvas!.width, drawCanvas!.height);

      for (const circle of circles.current) {
        const rect = drawCanvas!.getBoundingClientRect();
        const ratioX = canvaSize.width / circle.width;
        const ratioY = canvaSize.height / circle.height;
        console.log("ratio", ratioX, ratioY);
        console.log("circle", circle, rect, canvaSize);

        const translatedX = circle.x * ratioX;
        const translatedY = circle.y * ratioY;
        const translatedRadius = circle.radius * Math.min(ratioX, ratioY);

        drawContext!.beginPath();
        drawContext!.arc(
          translatedX,
          translatedY,
          translatedRadius,
          0,
          2 * Math.PI
        );
        drawContext!.strokeStyle = circle.color;
        drawContext!.lineWidth = 5;
        drawContext!.stroke();
        drawContext!.closePath();

        drawContext!.fillStyle = circle.color;
        drawContext!.font = "25px Arial";
        drawContext!.textAlign = "center";
        drawContext!.textBaseline = "middle";
        drawContext!.fillText(
          circle.number.toString(),
          translatedX,
          translatedY
        );
      }
    }

    drawCanvas?.addEventListener("mousedown", handleMouseDown);
    drawCanvas?.addEventListener("mousemove", handleMouseMove);
    drawCanvas?.addEventListener("mouseup", handleMouseUp);

    return () => {
      drawCanvas?.removeEventListener("mousedown", handleMouseDown);
      drawCanvas?.removeEventListener("mousemove", handleMouseMove);
      drawCanvas?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [roomSession, windowsize, canvaSize]);

  useEffect(() => {
    let displayCanvas: HTMLCanvasElement;
    let displayContext: CanvasRenderingContext2D | null;
    if (displayCanvasRef.current) {
      displayCanvas = displayCanvasRef.current;
      displayContext = displayCanvas.getContext("2d");
      if (metadata && metadata.height && metadata.circleData) {
        redrawCanvas(metadata.circleData, metadata.width, metadata.height);
      }
    }

    function redrawCanvas(circles: any[], width: number, height: number) {
      displayContext!.clearRect(0, 0, canvaSize.width, canvaSize.height);

      circles.forEach((circle) => {
        const ratioX = canvaSize.width / width;
        const ratioY = displayCanvas.height / height;

        const translatedX = circle.x * ratioX;
        const translatedY = circle.y * ratioY;
        const translatedRadius = circle.radius * Math.min(ratioX, ratioY);

        displayContext!.beginPath();
        displayContext!.arc(
          translatedX,
          translatedY,
          translatedRadius,
          0,
          2 * Math.PI
        );

        displayContext!.strokeStyle = circle.color;
        displayContext!.lineWidth = 5;
        displayContext!.stroke();
        displayContext!.closePath();

        displayContext!.fillStyle = circle.color;
        displayContext!.font = "25px Arial";
        displayContext!.textAlign = "center";
        displayContext!.textBaseline = "middle";
        displayContext!.fillText(
          circle.number.toString(),
          translatedX,
          translatedY
        );
      });
    }
  }, [metadata]);

  const eraseCanvas = async (canvas: HTMLCanvasElement | null) => {
    if (!roomSession) return;
    const context = canvas!.getContext("2d");
    context!.clearRect(0, 0, canvas!.width, canvas!.height);
    setShowCanva(false);
    setCanvaData([
      ...canvadata,
      {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        color: "",
        radius: 0,
        type: "erase",
        number: 0,
        timing: elapsedTime,
        recording_id: "",
      },
    ]);
    circles.current = [];
    counter.current = 1;
    roomSession
      .updateMeta({
        circleData: circles.current,
        width: canvas!.width,
        height: canvas!.height,
      })
      .then(() => {
        console.log("Meta updated successfully.");
      })
      .catch((error: any) => {
        console.log("Failed to update meta:", error);
      });
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (startTime !== null) {
      interval = setInterval(() => {
        const currentTime = Date.now();
        setElapsedTime(currentTime - startTime);
      }, 1);
    }

    return () => clearInterval(interval);
  }, [startTime]);

  const leaveRoom = async () => {
    eraseCanvas(drawCanvasRef.current);
    
    if (!roomSession) {
      return;
    }

    const recs = (await roomSession.getRecordings()).recordings[0];
    const dbData = canvadata.map((obj) => ({
      ...obj,
      recording_id: recs.id,
    }));

    await roomSession.hangup();
    onClose();
    setStartTime(null);
    // setModalOpen!(false);

    console.log("dbData", dbData);

    try {
      roomSession?.join();
      roomSession.updateMeta({ leave: true });

      await roomSession.leave();
      API.post("/oauth/deleteVideoToken", { note_id: notes.id });
      API.post("/storeCanvas", dbData);

      await createCommentFunc(
        {
          noteId: notes.id,
          userId: store.getState().authUserReducer.currentUser?.id,
          commentContent: roomSession?.id,
        },
        "VIDEO"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getMetadata = (e: any) => {
    if (document.getElementById("video")) {
      // const rect = document.getElementById("video")!.getBoundingClientRect();
      const rect = document
        .getElementById("video")!
        .getElementsByTagName("video")[0]
        ?.getBoundingClientRect();

      console.log("rect", rect);
      setCanvaSize({
        // width: metadata.aspectRatio ? rect.height * metadata.aspectRatio : rect.width,
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left,
      });
    }
    setMetadata(e.room.meta);
  };

  const handleVideo = async () => {
    console.log("video", videoMuted);
    if (!roomSession) {
      return;
    }
    if (videoMuted) {
      try {
        await roomSession.videoUnmute({ memberId: self?.id });
        muteVideo(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      // self?.video.mute();
      try {
        await roomSession?.videoMute({ memberId: self?.id });
        muteVideo(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAudio = async () => {
    if (!roomSession) {
      return;
    }
    if (audioMuted) {
      try {
        await roomSession?.audioUnmute({ memberId: self?.id });
        setAudioMuted(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await roomSession?.audioMute({ memberId: self?.id });
        setAudioMuted(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onRoomJoined = async () => {
    setRoomJoined(true);

    await roomSession?.videoMute();
    await roomSession.setHideVideoMuted(true);

    muteVideo(true);

    await roomSession?.startRecording();

    setStartTime(Date.now());
  };

  const toggleCanva = async () => {
    if (showCanva) {
      eraseCanvas(drawCanvasRef.current);
      setShowCanva(false);
    } else {
      if (document.getElementById("video")) {
        // const rect = document.getElementById("video")!.getBoundingClientRect();
        const rect = document
          .getElementById("video")!
          .getElementsByTagName("video")[0]
          ?.getBoundingClientRect();

        setCanvaSize({
          width: rect.width,
          height: rect.height,
          top: rect.top,
          left: rect.left,
        });
      }
      console.log(
        "toggle_canva",
        roomSession,
        document.getElementById("video"),
        token
      );
      if (!roomSession) {
        return;
      }
      // if (canva) {
      //     showCanva(false);
      // } else {
      // showCanva(true);
      setShowCanva(true);
    }
  };

  const copyToClipboard = (): void => {
    const textToCopy = inviteLink;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard:", textToCopy);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
    toast.success("Copied to Clipboard");
    setOpen(false);
  };

  const sendSMS = (phoneNumber: string): void => {
    const currentUser = store.getState().authUserReducer.currentUser;
    const isSalesUser = store.getState().authUserReducer.isSalesUser;
    console.log("Sending SMS to default number", phoneNumber);
    if (!phoneNumber) {
      toast.error("phone number not found");
      return;
    }

    const values = {
      contact_number: phoneNumber,
      content: `Hi, ${currentUser?.first_name} ${currentUser?.last_name} has sent you a invite link. Please click on the link to get over video call: ${inviteLink}`,
      smsFrom: isSalesUser ? currentUser?.phone : "",
      attachments: null,
    };
    console.log(currentUser, isSalesUser, values);
    // sendSms(values);
  };
  const sendSMSToAnotherNumber = (): void => {
    console.log(anotherPhoneNumber);
    if (anotherPhoneNumber) {
      sendSMS(anotherPhoneNumber);
    } else {
      return;
    }
  };

  return (
    <Modal
      closeOnClickOutside={false}
      showCloseButton
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      {...rest}
    >
      <div className="w-[90vw] h-[calc(100vh-90px)]">
        <div className="w-full flex flex-col py-4 px-4 gap-4">
          <div className="w-full flex flex-col gap-4">
            <NoteCardHeader notes={notes} />
            <div className="w-full flex justify-start items-start">
              <div className="relative aspect-video w-[960px] h-[540px] bg-black">
                <canvas
                  className={` ${
                    showCanva ? "block" : "hidden"
                  } absolute top-0 left-0 border-2 border-red-500 z-50 `}
                  ref={drawCanvasRef}
                  width={canvaSize.width}
                  height={canvaSize.height}
                />
                <div id="video" className="-z-10">
                  <Video
                    token={token}
                    onRoomReady={onRoomReady}
                    onRoomJoined={onRoomJoined}
                    onMemberTalking={(e) =>
                      console.log(`Member ${e.member.id} is talking.`)
                    }
                    onMemberJoined={() => setShowVideo(!showVideo)}
                    onRoomUpdated={(e) => getMetadata(e)}
                    video={{ width: 1920, height: 1080 }}
                  />
                </div>
                <div className="absolute bottom-[4%] left-[50%] translate-x-[-50%] flex gap-6 z-50">
                  <button
                    onClick={() => handleAudio()}
                    className={`${
                      audioMuted
                        ? "bg-white text-black"
                        : " bg-[#e2e2e2]/20  text-white"
                    } flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1`}
                  >
                    {audioMuted ? <MicOff /> : <Mic />}
                  </button>
                  <button
                    onClick={() => handleVideo()}
                    className={`${
                      videoMuted
                        ? "bg-white text-black"
                        : " bg-[#e2e2e2]/20  text-white"
                    } flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1`}
                  >
                    {videoMuted ? <VideoOff /> : <VideoIcon />}
                  </button>
                  <button
                    onClick={() => toggleCanva()}
                    className={`${
                      showCanva
                        ? "bg-white text-black"
                        : " bg-[#e2e2e2]/20  text-white"
                    } flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1`}
                  >
                    {showCanva ? <Eraser /> : <PenTool />}
                  </button>
                  <button
                    disabled={!active}
                    onClick={() => {
                      try {
                        toggle();
                      } catch (e) {
                        toast.error("Something went wrong !");
                      }
                    }}
                    className={` ${
                      !active && "opacity-50"
                    } flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1  bg-[#e2e2e2]/20  text-white`}
                  >
                    {screenShareActive ? <MonitorOff /> : <Monitor />}
                  </button>
                  <button
                    onClick={() => setOpen(true)}
                    className=" flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1  bg-[#e2e2e2]/20  text-white "
                  >
                    <Share2 />
                  </button>

                  <button
                    onClick={() => leaveRoom()}
                    className="flex items-center justify-center w-12 h-12 rounded-md font-medium px-2 py-1  bg-red-600  text-white "
                  >
                    <PhoneOff />
                  </button>
                </div>
                {!roomJoined && (
                  <div className="flex w-full flex-col gap-2 h-full justify-center items-center">
                    <div className="loader"></div>
                    <h2 className="text-white">
                      Joining into the conversation
                    </h2>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-2 px-8">
                  <h4 className="font-semibold text-[14px] ">
                    Members in this Conference
                  </h4>
                  <ul className="flex gap-4">
                    {members.map((member, index) => (
                      <li
                        className={` ${
                          member.talking && "outline outline-blue-500/60"
                        } w-16 h-16 border rounded-md flex items-center justify-center `}
                        key={index}
                      >
                        <div className="flex flex-col gap-1 items-center">
                          <User className="text-gray-600" />
                          <p className="text-[12px] line-clamp-1 text-center">
                            {member.name}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="max-h-[53vh] overflow-y-auto">
                  <NoteCommentField notes={notes} />
                </div>
              </div>
            </div>
            <NoteAddComment notes={notes} />
          </div>
          {/* <NoteCard notes={notes} /> */}
        </div>
      </div>
      <Modal
        isOpen={open}
        hasBackbutton={isAnotherNumber}
        onClickBackButton={() => setAnotherNumber(false)}
        onClose={() => setOpen(false)}
        title="Invite to Conference"
        closeOnClickOutside
        showCloseButton
      >
        <div className="flex flex-col gap-2 py-2 px-4 items-start">
          {isAnotherNumber ? (
            <>
              <div className="w-full flex gap-1 flex-col">
                <label className="text-[14px] font-medium" htmlFor="key">
                  Phone Number
                </label>
                <input
                  className="py-1 px-2 w-full border rounded-sm focus:outline-blue-500/50"
                  id="key"
                  type="tel"
                  onChange={(e) => setAnotherPhoneNumber(e.target.value)}
                />
              </div>
              <div className="flex gap-6 mt-4 w-full justify-center">
                <button
                  onClick={() => sendSMSToAnotherNumber()}
                  className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
                >
                  Send SMS
                </button>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={copyToClipboard}
                className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950"
              >
                Copy to clipboard
              </button>
              {notes?.Contact?.phone && (
                <button
                  onClick={() => sendSMS(notes?.Contact?.phone)}
                  className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950"
                >
                  Send Sms to {notes?.Contact?.phone}
                </button>
              )}

              <button
                onClick={() => setAnotherNumber(true)}
                className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950"
              >
                Send SMS to another number
              </button>
            </>
          )}
        </div>
      </Modal>
    </Modal>
  );
};

export default VideoCallModal;
