import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import React, { useState } from "react";
import { useAppSelector } from "store/index";

export const useGetNoteCountofDay = (status: any) => {
  const { isSalesUser } = useAppSelector((state) => state.authUserReducer);

  const { data } = useQuery(["noteCount", status], async () => {
    const res = await API.get("/notes/noteCount", {
      params: {
        status: status === "TIMELINE" ? "" : status,
        channel: isSalesUser ? "sales" : "",
      },
    });
    return res?.data?.count;
  });
  return data;
};

export default useGetNoteCountofDay;
